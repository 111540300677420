import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import Head from 'next/head';
import MultiCarousel from 'react-multi-carousel';
import Layout from '../../components/layouts/layout';
import Seo from '../../components/seo';
import { initializeApollo, addApolloState } from '../../lib/apolloClient';
import FeatureCard from '../../components/feature-card/feature-card';
import LiveJoySticker from '../../components/live-joy-sticker/live-joy-sticker';
import ImageCarousel from '../../components/image-carousel/image-carousel';
import { getStrapiMedia } from '../../lib/utils';
import fetchHome from '../../lib/queries/fetchHome';
import styles from './home.module.scss';
import PostGroup from '../../components/post-group/post-group';
import ScheduleList from '../../components/schedule-list/schedule-list';
import useResponsive from '../../lib/useResponsive';
import ScrollContainer from 'react-indiana-drag-scroll';
import { orderBy, throttle } from 'lodash';

const Home = ({ homepage, blog, teacherTrainings }) => {
  const { isDesktop } = useResponsive();

  const [stickyStyles, setStickyStyles] = useState({});
  const [width, setWidth] = useState(0);
  const [scrollStyle, setScrollStyle] = useState({
    left: 50,
    right: 50,
  });
  const [scrollWidthState, setScrollWidthState] = useState(null);

  const stickyTag = useRef(null);
  const isDesktopRef = useRef(isDesktop);
  const introRef = useRef(null);
  const dragableRef = useRef(null);

  useEffect(() => {
    updateStickyStyles();

    window.addEventListener('scroll', updateStickyStyles);
    window.addEventListener('resize', updateStickyStyles);

    return () => {
      window.removeEventListener('scroll', updateStickyStyles);
      window.removeEventListener('resize', updateStickyStyles);
    };
  }, []);

  useEffect(() => {
    isDesktopRef.current = isDesktop;
  }, [isDesktop]);

  const updateStickyStyles = (event) => {
    const offset = introRef.current.offsetTop;
    const bottomOffset = 0;

    if (offset <= window.scrollY) {
      setStickyStyles({
        position: 'absolute',
        bottom: bottomOffset,
      });
    } else {
      if (window.innerHeight < offset + 200) {
        setStickyStyles({
          display: 'none',
        });
      } else {
        setStickyStyles({
          position: 'fixed',
          bottom: bottomOffset,
        });
      }
    }
    setWidth(window.innerWidth);
  };

  return (
    <Layout logoType="big">
      {/* <Seo seo={homepage.seo} /> */}
      <Head>
        <title>The Sacred Fig</title>
        <meta name="description" content={homepage.introDescription} />
        <meta name="image" content={getStrapiMedia(homepage.introMedia[0])} />
      </Head>

      <div className={styles.intro}>
        <div ref={introRef} className={styles.wrapper}>
          {isDesktop ? (
            homepage.introMedia?.map((media, idx) => (
              <div
                key={idx + '-introMedia-' + media.name}
                className={styles.media}
                style={{
                  backgroundImage: `url(${getStrapiMedia(media)})`,
                }}
              ></div>
            ))
          ) : (
            <div
              key={'0-introMedia-' + homepage.introMedia[0].name}
              className={styles.media}
              style={{
                backgroundImage: `url(${getStrapiMedia(homepage.introMedia[0])})`,
              }}
            ></div>
          )}

          <div ref={stickyTag} className={styles.sticker} style={stickyStyles}>
            <LiveJoySticker></LiveJoySticker>
          </div>
        </div>

        <div className={styles.description}>
          <p>{homepage.introDescription}</p>
        </div>
      </div>

      <ScrollContainer
        ref={dragableRef}
        vertical={false}
        className={styles.features}
        style={{
          cursor: width < 1450 && 'grab',
          paddingLeft: scrollStyle.left,
          paddingRight: scrollStyle.right,
          transition: 'all 0.5s ease',
        }}
        onScroll={(scrollLeft, scrollTop, scrollWidth, scrollHeight) => {
          if (scrollWidthState === null) {
            setScrollWidthState(scrollWidth);
          }
          if (scrollLeft === 0) {
            setScrollStyle({
              left: 200,
              right: 50,
            });
          } else if (
            scrollWidth - parseInt(scrollLeft) == dragableRef.current.container.current.clientWidth &&
            scrollWidth === scrollWidthState
          ) {
            console.log('masuk');
            setScrollStyle({
              left: 50,
              right: 200,
            });
          }
        }}
        onEndScroll={() => {
          console.log('keluar');
          setScrollStyle({
            left: 50,
            right: 50,
          });
        }}
      >
        {homepage.features?.map((feature, i) => (
          <div className={styles.card} key={i}>
            <FeatureCard key={i} feature={feature} mediaHeight={isDesktop ? 580 : 480}></FeatureCard>
          </div>
        ))}
      </ScrollContainer>

      <ImageCarousel images={homepage.images} style={{ height: isDesktop ? '100vh' : '60vh' }}></ImageCarousel>

      <div className={styles.schedule}>
        <ScheduleList title="Schedule" trainings={teacherTrainings} />
      </div>

      <div className={styles.blog}>
        <div className={styles.title}>
          <p>{blog.title}</p>
        </div>

        {blog.posts_pair && <PostGroup postsPair={blog.posts_pair} limit={3}></PostGroup>}

        <Link href={'/blog'}>
          <a className={styles.more}>More</a>
        </Link>
      </div>
    </Layout>
  );
};

export async function getStaticProps() {
  const apolloClient = initializeApollo();

  const { data } = await apolloClient.query({
    query: fetchHome,
  });

  const trainings = data.teacherTrainings.map((item) => {
    return {
      ...item,
      key: 'Training',
    };
  });

  const retreats = data.retreats.map((item) => {
    return {
      ...item,
      key: 'Retreat',
    };
  });

  const trainingRetreat = trainings.concat(retreats);

  return addApolloState(apolloClient, {
    props: {
      homepage: data.homepage,
      blog: data.blog,
      teacherTrainings: orderBy(trainingRetreat, ['start_date'], ['asc']),
    },
    revalidate: 1,
  });
}

export default Home;
