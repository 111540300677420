import styles from "./live-joy-sticker.module.scss";

export default function LiveJoySticker({ sticker }) {
  return (
    <div className={styles.sticker}>
      <p className={styles.first}>live</p>
      <p className={styles.second}>joy</p>
    </div>
  );
}
