import Link from "next/link";
import { getStrapiMedia } from "../../lib/utils";
import styles from "./show-card.module.css";

export default function ShowCard({
  children,
  media,
  image,
  style: parentStyle,
  imgWidth,
  imgCols,
  centerText,
}) {
  return (
    <div className={styles.category} style={{ ...parentStyle }}>
      <div
        style={{
          width: `${(25 - imgCols) * 4}vw`,
          flex: `0 1 ${100 - imgWidth}%`,
          alignSelf: centerText ? "center" : "auto",
        }}
      >
        {children}
      </div>
      {media && (
        <div
          className={styles.media}
          style={{
            backgroundImage: `url(${getStrapiMedia(media)})`,
            width: `${imgCols * 4}vw`,
            flex: `0 0 ${imgWidth}%`,
          }}
        ></div>
      )}

      {image && (
        <div
          className={styles.media}
          style={{
            backgroundImage: `url(${image})`,
            width: `${imgCols * 4}vw`,
            flex: `0 0 ${imgWidth}%`,
          }}
        ></div>
      )}
    </div>
  );
}
