import Link from 'next/link';
import { useState } from 'react';
import Moment from 'react-moment';
import useResponsive from '../../lib/useResponsive';
import { getStrapiMedia } from '../../lib/utils';
import ShowCard from '../show-card/show-card';
import styles from './schedule-list.module.scss';

export default function ScheduleList({ title, trainings, type = 'trainings', style: parentStyle }) {
  const [activeSchedule, setActiveSchedule] = useState(null);
  const [posMouse, setPosMouse] = useState({ x: -1, y: -1 });

  const { isDesktop } = useResponsive();

  const parentSlug = (training = 'type') => {
    if (training === 'Training') {
      return 'trainings';
    } else if (training === 'Retreat') {
      return 'retreats';
    } else {
      return type;
    }
  };

  function _showScheduleImage(schedule) {
    setActiveSchedule(schedule);
  }

  function _onMouseMove(e) {
    setPosMouse({ x: e.nativeEvent.x, y: e.nativeEvent.y });
  }

  const renderForMobile = () => {
    return trainings?.map((training, idx) => (
      <Link href={`/${parentSlug(training?.key)}/${training.slug}`} key={idx + training.slug}>
        <a className={styles.item}>
          <ShowCard media={training.featured_image} style={{ height: 86, margin: '50px 0' }} imgCols={6}>
            <div className={styles.description}>
              <p>
                <Moment format="MMM D" date={training.start_date}></Moment>
                &nbsp; - &nbsp;
                <Moment format="MMM D, YYYY" date={training.end_date}></Moment>
              </p>
              <p> {training.title}</p>
              <p> {training.location}</p>
            </div>
          </ShowCard>
        </a>
      </Link>
    ));
  };

  const renderForDesktop = () => {
    return (
      <>
        <div className={styles.header}>
          <div style={{ width: '30%' }}> Date</div>
          <div style={{ width: '50%' }}> Program</div>
          <div style={{ width: '20%' }}> Location</div>
        </div>

        {trainings?.map((training, idx) => (
          <Link href={`/${parentSlug(training?.key)}/${training.slug}`} key={idx + training.slug}>
            <a
              className={styles.item}
              onMouseOver={_showScheduleImage.bind(this, training)}
              onMouseLeave={_showScheduleImage.bind(this, null)}
            >
              <div style={{ width: '30%' }}>
                <Moment format="MMM D" date={training.start_date}></Moment>
                &nbsp; - &nbsp;
                <Moment format="MMM D, YYYY" date={training.end_date}></Moment>
              </div>
              <div style={{ width: '50%' }}> {training.title}</div>
              <div style={{ width: '20%' }}> {training.location}</div>
              {activeSchedule == training && (
                <img src={getStrapiMedia(training.featured_image)} width="20%" style={{ left: posMouse.x + 20 }}></img>
              )}
            </a>
          </Link>
        ))}
      </>
    );
  };

  return (
    <div className={styles.schedules} onMouseMove={_onMouseMove.bind(this)} style={parentStyle}>
      <p className={styles.title}>{title}</p>

      {isDesktop ? renderForDesktop() : renderForMobile()}
    </div>
  );
}
