import { useState } from 'react';
import useInterval from '../../lib/useInterval';
import useResponsive from '../../lib/useResponsive';
import { getStrapiMedia } from '../../lib/utils';
import styles from './image-carousel.module.css';

export default function ImageCarousel({ images, style: parentStyle, autoplay = true }) {
  const [activeIdx, setActiveIdx] = useState(2);
  // const { isDesktop } = useResponsive();

  const classChanges = [styles.right, styles.left, styles.forward, styles.forward];

  useInterval(() => {
    if (autoplay) {
      setActiveIdx(activeIdx == images.length ? 1 : activeIdx + 1);
    }
  }, 1000);

  // const onClickCarousel = (e) => {
  //   if (window.innerWidth / 2 < e.nativeEvent.x) {
  //     setActiveIdx(activeIdx == images.length - 1 ? activeIdx : activeIdx + 1);
  //   } else {
  //     setActiveIdx(activeIdx == 1 ? 1 : activeIdx - 1);
  //   }
  // };

  // const renderForMobile = () => {
  //   return (
  //     <div
  //       key={images[activeIdx].name}
  //       className={classChanges[activeIdx % 4]}
  //       style={{
  //         backgroundImage: `url(${images[activeIdx].url})`,
  //         zIndex: activeIdx,
  //       }}
  //     ></div>
  //   );
  // };

  const renderForDesktop = () => {
    return (
      <>
        {activeIdx > 2 && activeIdx < images.length && (
          <div
            key={images[activeIdx - 3].name}
            className={classChanges[(activeIdx - 3) % 4]}
            style={{
              backgroundImage: `url(${images[activeIdx - 3].url})`,
              zIndex: activeIdx - 3,
            }}
          ></div>
        )}
        {activeIdx > 1 && activeIdx < images.length && (
          <div
            key={images[activeIdx - 2].name}
            className={classChanges[(activeIdx - 2) % 4]}
            style={{
              backgroundImage: `url(${images[activeIdx - 2].url})`,
              zIndex: activeIdx - 2,
            }}
          ></div>
        )}
        {activeIdx > 0 && activeIdx <= images.length && (
          <div
            key={images[activeIdx - 1].name}
            className={classChanges[(activeIdx - 1) % 4]}
            style={{
              backgroundImage: `url(${images[activeIdx - 1].url})`,
              zIndex: activeIdx - 1,
            }}
          ></div>
        )}
        {activeIdx < images.length && (
          <div
            key={images[activeIdx].name}
            className={classChanges[activeIdx % 4]}
            style={{
              backgroundImage: `url(${images[activeIdx].url})`,
              zIndex: activeIdx,
            }}
          ></div>
        )}
        {activeIdx === images.length && (
          <div
            key={images[activeIdx - images.length].name}
            className={classChanges[(activeIdx - 2) % 4]}
            style={{
              backgroundImage: `url(${images[activeIdx - images.length].url})`,
              zIndex: activeIdx - images.length,
            }}
          ></div>
        )}
      </>
    );
  };
  return (
    <div
      className={styles.carousel}
      style={parentStyle}
      // onClick={onClickCarousel.bind(this)}
    >
      {/* {isDesktop ? renderForDesktop() : renderForMobile()} */}
      {renderForDesktop()}
    </div>
  );
}
