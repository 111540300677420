import React from "react";
import Link from "next/link";
import Moment from "react-moment";
import { getStrapiMedia } from "../../lib/utils";
import styles from "./post-card.module.scss";

const PostCard = ({ post, parentStyle }) => {
  const imageUrl = getStrapiMedia(post.featuredImg);

  return (
    <Link href={`/post/${post.slug}`}>
      <div className={styles.card} style={parentStyle}>
        <div className={styles.media}>
          <img src={imageUrl} alt={post.title} width="100%"></img>
        </div>
        <div className={styles.title}>{post.title}</div>
        <div className={styles.footer}>
          <p>{post.shortDescription}</p>
          {/* <div className={styles.date}>
            <Moment format="MM.DD.YY">{post.published_at}</Moment>
          </div> */}
        </div>
      </div>
    </Link>
  );
};

export default PostCard;
