import PostCard from '../post-card/post-card';
import styles from './post-group.module.scss';

export default function PostGroup({ postsPair, limit }) {
  return (
    <>
      <div className={styles.group}>
        {postsPair?.slice(0, limit).map((pair) => (
          <div className={styles.row} key={pair?.left_post?.slug}>
            {pair?.left_post && (
              <div className={styles.post} style={{ flexBasis: `${pair?.left_width}%` }}>
                <PostCard key={pair?.left_post?.slug} post={pair?.left_post}></PostCard>
              </div>
            )}

            {pair?.right_post && (
              <div className={styles.post} style={{ flexBasis: `${pair?.right_width}%` }}>
                <PostCard key={pair?.right_post?.slug} post={pair?.right_post}></PostCard>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
